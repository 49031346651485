<template>
  <div class="record-page team">
    <headerBar :title="i18n.t('team.title')"  :showArrow="true" @back="back"></headerBar>
    <div class="record-main">
       <div class="search-line flex-center-between">
        <div class="time-box" @click="showDate(1)">
          {{form.start || $t('record.label1')}}
        </div>
        <div class="space">-</div>
        <div class="time-box" @click="showDate(2)">
          {{form.end || $t('record.label2')}}
        </div>
        <div class="btn flex-center" @click="search">
          {{i18n.t('record.label3')}}
        </div>
      </div>

      <div class="team-statistics flex-wrap">
        <div class="info">
          <div>{{i18n.t('team.label4')}}($)</div>
          <div class="count">$0</div>
        </div>
        <div class="info ">
          <div>{{i18n.t('team.label5')}}($)</div>
          <div class="count">$0</div>
        </div>
        <div class="info">
          <div>{{i18n.t('team.label6')}}($)</div>
          <div class="count">$0</div>
        </div>
        <div class="info ">
          <div>{{i18n.t('team.label7')}}($)</div>
          <div class="count">$0</div>
        </div>
        <div class="info">
          <div>{{i18n.t('team.label8')}}($)</div>
          <div class="count">${{teamData.TotalCommission ? teamData.TotalCommission.toFixed(2) : 0}}</div>
        </div>
        <div class="info">
          <div>{{i18n.t('team.label9')}}($)</div>
          <div class="count">$0</div>
        </div>
        <div class="info">
          <div>{{i18n.t('team.label10')}}</div>
          <div class="count">0</div>
        </div>
        <div class="info ">
          <div>{{i18n.t('team.label11')}}</div>
          <div class="count">{{teamData.TotalUserCount || 0}}</div>
        </div>
        <div class="info">
          <div>{{i18n.t('team.label12')}}</div>
          <div class="count">0</div>
        </div>
        <div class="info ">
          <div>{{i18n.t('team.label13')}}</div>
          <div class="count">0</div>
        </div>
      </div>

      <div class="team-main">
        <div class="tabs flex-center">
          <div class="tab" :class="tabIndex == i ? 'active' : ''" v-for="(item, i) in tabList" :key="i" @click="changeTab(i)">{{item}}</div>
        </div>

        <div class="user-list">
          <List
            v-if="userList.length > 0"
            v-model="loading"
            :finished="finished"
            :finished-text="i18n.t('common.noMore')"
            @load="onLoad">
            <div class="list flex-center-between"  v-for="(item, i) in userList" :key="i">
              <div class="header header-3"></div>
              <div class="flex-1 flex-center-between">
                <div>
                  <div>{{i18n.t('team.label14')}}：test01</div>
                  <div>{{i18n.t('team.label15')}}：0</div>
                  <div>{{i18n.t('team.label16')}}：0</div>
                </div>
                <div class="text-right">
                  <div>{{i18n.t('team.label17')}}：13312341243</div>
                  <div>{{i18n.t('team.label18')}}：0</div>
                  <div>{{i18n.t('team.label19')}}：2023/03/03</div>
                </div>
              </div>
            </div>
          </List>
        </div>
      </div>
    </div>


    <Popup v-model:show="showModal" position="bottom">
      <DatetimePicker 
        style="width: 100%"
        v-model="currentDate"
        type="date"
        @confirm="changeDate"
        @cancel="cancel"
        :title="title"></DatetimePicker>
    </Popup>
  </div>
</template>
<script>
import { Button, List, Popup, DatetimePicker, Icon, Tabs, Tab } from 'vant'
import headerBar from '@/components/header'
import moment from 'moment'
import { teamReport } from '@/api/user'
export default {
  components: {
    headerBar,
    Button,
    List,
    Popup,
    DatetimePicker,
    Icon,
    Tabs,
    Tab
  },
  data() {
    return {
      form: {
        start: '',
        end: ''
      },
      currentDate: [],
      page: {
        page: 1,
        limit: 10
      },
      teamData: {},
      isInit: false,
      loading: false,
      finished: false,
      totalPage: 1,
      userList: [],
      tabList: [this.i18n.t('team.tab1'), this.i18n.t('team.tab2'), this.i18n.t('team.tab3')],
      title: '',
      showModal: false,
      tabIndex: 0
    }
  },
  mounted() {
    this.form.start = moment().format('YYYY-MM-DD')
    this.form.end = moment().format('YYYY-MM-DD')
    // this.init()
    this.initTeam()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    initTeam() {
      let param = {
        start: this.form.start,
        end: this.form.end
      }
      teamReport(param).then(res => {
        this.teamData = res.data
      })
    },
    init(isAdd) {
      this.loading = false
      return
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      financeSetupList(form).then(res => {
        if (res.data.Items) {
          if (isAdd) {
            this.userList = this.userList.concat(res.data.Items)
          } else {
            this.userList = res.data.Items
          }
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.page) {
             this.finished = true;
          }
        }
        this.isInit = true
        this.loading = false
        
      })
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    search() {
      this.initTeam()
    },
    changeDate(date) {
      if (this.dateType == 1) {
        this.form.start = moment(date).format('YYYY-MM-DD')
      } else {
        this.form.end = moment(date).format('YYYY-MM-DD')
      }
      this.showModal = false
    },
    showDate(type) {
      this.dateType = type
      this.showModal = true
      if (type == 1) {
        this.title = this.i18n.t('team.label1')
      } else {
        this.title = this.i18n.t('team.label2')
      }
    },
    cancel() {
      this.showModal = false
    },
    changeTab(i) {
      this.tabIndex = i
      this.initTeam()
    }
  }
}
</script>